<template>
  <div class="app-container">
    <baseTable :option="option" :api="getList" :resetMergeData="resetMergeData">
      <template #menu="{ row }">
        <edit-btn :sup_this="sup_this" :data="row" v-if="permissionList.edit" @success="init" />
        <statusBtn
          v-if="permissionList.disable"
          :data="row"
          statusProp="isEnable"
          :expandParmas="{templateUsageType:2}"
          type="attributeDisable"
          :afterSubmit="init"
          v-bind="GEARS_ENABLED_OBJ"
        ></statusBtn>
        <!-- <template v-if="permissionList.disable"> -->
        <!-- <disable-btn :sup_this="sup_this" :data="row" v-if="row.enabled == ENABLE_STATUS" />
          <enable-btn :sup_this="sup_this" :data="row" v-else /> -->
        <!-- </template> -->
      </template>
    </baseTable>
  </div>
</template>

<script>
import statusBtn from '@/views/components/statusBtn'
import editBtn from './module/editBtn.vue'
import disableBtn from './module/disableBtn'
import { GEARS_ENABLED_OBJ } from '@/utils/constant'
import enableBtn from './module/enableBtn'
import attributeApi from '@/api/productTemplate/attributeManage'
import { checkPermission } from '@/utils'
import { option } from './module/option'
import { ENABLE_STATUS, DISABLE_STATUS } from '@/utils/constant'

export default {
  components: { editBtn, disableBtn, enableBtn, statusBtn },

  data() {
    return {
      option,
      ENABLE_STATUS,
      DISABLE_STATUS,
      GEARS_ENABLED_OBJ,
      sup_this: this,
      permissionList: {
        disable: checkPermission(['externaladmin:productTemplate:attributeManage:disable']),
        edit: checkPermission(['externaladmin:productTemplate:attributeManage:edit'])
      },
      resetMergeData: {
        templateUsageType: 2,
        orderItems: [
          { asc: false, column: 'create_time' },
          { asc: true, column: 'id' }
        ]
      }
    }
  },

  computed: {
    getList() {
      return attributeApi.list
    },
    
  }
}
</script>

<style lang="scss" scoped></style>
